<template>
	<div class="aCustomSelect" v-show="!fieldIsHidden">

		<!-- mask to close field if user click outside the field -->
		<div
				class="inputmask"
				v-if="isOptionsListOpen"
				@click="closeList"
		></div>

		<div class="selectfield field">

			<label :for="fieldname" v-html='label + `&nbsp;<span v-if="isRequired" class="req" tabindex="-1">*</span>`'></label>

			<div class="dropdown" :class="{'open': isOptionsListOpen}">

				<input
						type="text"
						autocomplete="off"
						:id="fieldname"
						:placeholder="placeholder"
						v-model="textInField"
						@input="userInputChanged"
						@keyup.prevent.down="down"
						@keyup.prevent.up="up"
						@keyup.prevent.enter="enter"
						@keydown.tab="tabPressed"
						@focus="turnAutoFillOff"
						@blur="turnAutoFillOn"
						tabindex="0"
						:class="{hasError: isInError, hasSuccess: isInSuccess, isLoading: isLoading}"
				>

				<!-- Toggler -->
				<div class="toggle" @click="displaySuggestions(!isOptionsListOpen)" :class="{'active': isOptionsListOpen}">
					<span class="arrow-up" v-show="isOptionsListOpen">
						<svg height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
							 viewBox="0 0 36 36" xml:space="preserve">
						<g>
							<polygon points="0,24 18,6 36,24 31,28 18,15 5,28"/>
						</g>
					</svg>
				</span>
					<span class="arrow-down" v-show="!isOptionsListOpen">
					<svg height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
						 viewBox="0 0 36 36" xml:space="preserve">
					<g>
						<polygon  points="5,8 18,21 31,8 36,12 18,30 0,12"/>
					</g>
				</svg>
			</span>
				</div>

				<!-- Suggestions list -->
				<ul class="options-list" style="overflow-y: auto"
					tabindex="-1"
					@keydown.tab="tabPressed"
					@keyup.prevent.enter="enter"
				>
					<li :class="{'active': currentActiveSuggestion === index}" v-for="(suggestion, index) in matches"
						:key="index"
						@click="selectSuggestion(suggestion, index)"
						tabindex="0"
					>{{suggestion[1][description]}}</li>
					<li v-show="noResult" @click="closeWithoutSelection">{{$t('inputLabel.noResult')}}</li>
				</ul>

			</div>
		</div>

		<div class="errorMessage" v-if="isInError">{{errorMessage}}</div>
		<div class="helper" role="text" :aria-label="'More info for ' + label + ' field'">{{helper}}</div>


	</div>
</template>

<!-- ================================================================================== -->

<script>
	import FormFields from "../mixins/FormFields";
	import config from "../config";

	export default {
		name:"customSelect",

		mixins: [FormFields],

		props : {
			label : {
				type: String,
				required: true
			},
			dataFrom : {
				type: String,
				required: true
			},
			identifier : {
				type: String,
				required: true
			},
			description : {
				type: String,
				required: true
			},
			modelValue: {
				required: false,
			},
			isReparticipation: {
				type: Boolean,
				required: true,
			},
			caractersBeforeOpenList : {
				type: Number,
				required: false,
				default: 3
			},
			autocomplete: {
				type: Boolean,
				required: true,
				default: true
			},
		},

		data(){
			return {
				isOptionsListOpen : false,
				options : [],
				optionsFullList: [],
				userInput : "",
				displayedInTheField: null,
				currentActiveSuggestion : null, // not really reliable when one types the value, without using the list.
				errorMessage : '',
				autoFill : true,
				fieldIsHidden: false,
				config: config,
				noResult: false,
				keyDownWasPressed: false,
				tabKeyWasPressed: false,
			}
		},

		computed: {
			matches() {
				return Object.entries(this.options).filter((option) => {
					let optionText = escape(option[1][this.description]).toUpperCase();
					return optionText.match(escape(this.displayedInTheField).toUpperCase());
				});
			},

			textInField: {
				get(){
					return this.displayedInTheField;
				},
				set(newVal){
					console.log("reset de textInField");
					this.displayedInTheField = newVal;
				}
			}

		},

		methods: {

			turnAutoFillOff(){
				this.autoFill = false;
			},

			turnAutoFillOn(){
				this.autoFill = true;
			},

			getSuggestionsFromAPI() {
				this.setLoading();
				console.log("Get suggestions for " + this.dataFrom);
				this.axios.get(`/dropdown/${config.idlead}/${this.dataFrom}?lang=${this.$i18n.locale.slice(0,2)}`)

						.then(resp => {
							let self = this;
							this.options = resp.data.filter(function (item){
								return item[self.description] != '' && item[self.description] != null;
							});
							/* An exception: if there is only one product */
							/*if(this.options.length === 1){
								this.thereIsOnlyOneItem();
							}*/
              this.thereIsOnlyOneItem();
						})
						.catch(error => {
							if(error.response.status === 503){
								this.setNotLoading();
								this.$emit('maintenanceDetected');
							}else{
								console.log("Cannot get list from API");
								console.log(error);
							}
						})
						.then(()=>{
							console.log("List from API are put in place in " + this.fieldname);
							this.optionsFullList = this.options;
						});
				this.setNotLoading();
			},

			thereIsOnlyOneItem(){
				console.log("Only one product found ==> Filling and hide field");
				console.log(this.options[0]);
				this.fieldIsHidden = true;
        if(this.fieldname === "selectProduct"){
          console.log("selectProduct");
          this.$emit('update:modelValue', this.options[0].id);
        }
        if(this.fieldname === "selectProduct2"){
          console.log("selectProduct2");
          this.$emit('update:modelValue', this.options[1].id);
        }
				//this.$emit('update:modelValue', this.options[0].id);
				this.sendCheckSignal(true);
				this.giveSuccess();
			},

			displaySuggestions(bool){
				if(bool){
					console.log("Open the list? " + bool);
					this.removeText();
					this.getSuggestionsFromAPI(); /* to "refresh" the content of the list following current locale */
					this.openList();
				}else{
					this.closeList();
				}
			},

			userInputChanged() {

				if(this.displayedInTheField.length === 0){
					this.emptyAndCloseList();
				}
				/* User type some caracters => options list matching strings */
				if(this.displayedInTheField.length >= this.caractersBeforeOpenList){
					this.makeNewOptionsList(this.autocomplete);
					if(!this.isOptionsListOpen){ this.openList(); }
				}
				this.noResult = (this.displayedInTheField.length >= this.caractersBeforeOpenList && this.options.length < 1);
			},

			toggleListOpeness(){
				this.isOptionsListOpen = !this.isOptionsListOpen;
				if(this.isOptionsListOpen){
					this.displayedInTheField = "";
					this.makeNewOptionsList(this.autocomplete);
				}
			},
			emptyAndCloseList(){
				this.options = [];
				this.closeList();
			},
			openList() {
				console.log("Opening option list", 'low');
				/* remove previous options */
				this.options = [];
				/* recreate new options list */
				this.makeNewOptionsList(this.autocomplete);
				/* DOM open the list */
				this.isOptionsListOpen = true;
			},

			closeList() {
				this.noResult = false;
				console.log("Closing option list", 'low');
				this.currentActiveSuggestion = '';
				this.isOptionsListOpen = false;
			},

			closeWithoutSelection(){
				console.log("Closing the optionList without selection", 'low');
				this.textInField = "";
				this.closeList();
				this.currentActiveSuggestion = ""; /* maybe could be inchanged instead? */
				this.$emit('update:modelValue', null);
			},

			makeNewOptionsList(bool){
				/* If autocomplete is true, it's a partial list */
				if(bool){
					let userInput = this.displayedInTheField.toLowerCase();
					this.options = this.optionsFullList.filter(option => option.description.toLowerCase().includes(userInput));
				}
				/* If autocomplete is false, it's a full list */
				if(!bool){
					let userInput = this.displayedInTheField.toLowerCase();
					this.options = this.optionsFullList.filter(option => option.description.toLowerCase().includes(userInput));
				}
			},


			removeText(){
				this.currentActiveSuggestion = '';
				this.textInField = "";
			},

			selectSuggestion (suggestion, index) {
				this.closeList();
				this.displayedInTheField = suggestion[1][this.description];
				this.$emit('update:modelValue', suggestion[1][this.identifier]);
				this.currentActiveSuggestion = index;
				this.checkIfImValid();
			},

			up() {
				if(this.isOptionsListOpen && this.currentActiveSuggestion >= 0){
					this.upOneItem();
				}else if(this.isOptionsListOpen){
					this.displaySuggestions(false);
					this.currentActiveSuggestion = 0;
				}
			},

			down() {
				this.tabKeyWasPressed = false;

				if(this.isOptionsListOpen && this.currentActiveSuggestion < this.matches.length){

					this.downOneItem();

				}else if(!this.isOptionsListOpen){
					this.displaySuggestions(true);
					this.currentActiveSuggestion = 0;
				}
			},

			tabPressed(e){

				this.tabKeyWasPressed = true;

				if(this.isOptionsListOpen && this.currentActiveSuggestion < this.matches.length){

					if(e.shiftKey) {
						this.upOneItem();
					}else {
						this.downOneItem();
					}

				}else if(!this.isOptionsListOpen && !e.shiftKey){
					this.displaySuggestions(true);
					this.currentActiveSuggestion = 0;
				}

			},

			downOneItem() {
				if (this.currentActiveSuggestion < this.matches.length-1){
					this.currentActiveSuggestion += 1;
				}else if (this.currentActiveSuggestion >= this.matches.length-1 && this.tabKeyWasPressed === true){
					this.closeWithoutSelection();
				}
			},

			upOneItem() {
				if(this.currentActiveSuggestion > 0){
					this.currentActiveSuggestion -= 1;
				}else if (this.currentActiveSuggestion <= 0){
					this.closeWithoutSelection();
				}
			},

			setSelection(data){
				let itemCode = data[1][this.identifier];
				this.displayedInTheField = data[1][this.description];
				this.$emit('update:modelValue', itemCode);
				this.checkIfImValid();
			},

			setFieldInError(msg="Entry is invalid"){
				this.errorMessage = msg;
				this.checkIfImValid();
			},

			enter() {
				if(!this.matches[this.currentActiveSuggestion]){
					this.setFieldInError("Please choose an entry in the list"); // ??? is this ever used ? Yes, but overwritten by a checkIfImValid.
				}else{
					this.setSelection(this.matches[this.currentActiveSuggestion]);
				}

				this.displaySuggestions(false);
			},

			isThisFieldValid(){
				/* What is a valid answer ? An answer that is exactly in the list */
				let listSuggestions = [];
				for(let item in this.options){
					listSuggestions.push(this.options[item][this.description].toLowerCase());
				}
				return listSuggestions.includes(this.displayedInTheField.toLowerCase());
			},

			checkIfImValid(){
				console.log("I'm checking the validity of this field");
				if(this.isThisFieldValid()){
					// console.log("This field " + this.fieldname + " IS valid.");
					this.giveSuccess();
				}else{
					// console.log("This field " + this.fieldname + " IS NOT valid.");
					this.giveError();
				}
				this.sendCheckSignal(this.isThisFieldValid());
				return this.isThisFieldValid();
			},

			sendCheckSignal (bool=false){
				this.$emit('runCheck', {fieldname: this.fieldname, valid: bool});
			},

			sendDataToParent(){
				console.log("I send modelValue " + this.modelValue + " to parent");
				this.$emit('update:modelValue', this.modelValue);
				// as any answers is good here
				this.giveSuccess();
				this.$emit('runCheck', {fieldname: this.fieldname, valid: true});
			},


		},

		watch: {
			'$store.getters.getLocale': function(/* newLocale, oldLocale */){
				/* Watch locale to trigger a refresh on the list and label */
				this.getSuggestionsFromAPI();
			},
		},

		created() {
			this.getSuggestionsFromAPI();
			if(this.isReparticipation){
				this.sendDataToParent();
			}
		},

		updated() {
			/*
			if(this.modelValue && this.userInput.trim() === "" && this.autoFill) {
				let options = this.options.filter(el => el[this.identifier] === this.modelValue);
				let option = options[0];
				this.userInput = option[this.description];
				this.sendCheckSignal(this.checkIfImValid());
			}
			*/
		}

	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
</style>
